import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../../components/Share';
import ContactForm from '../../../components/ContactForm';
import Checkout from "../../../components/Checkout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <Row mdxType="Row">
        <Column colMd={1} colLg={1} mdxType="Column">
        </Column>
        <Column colMd={6} colLg={10} mdxType="Column">
          <em>Wizdough Chart Sync Indicator for NinjaTrader 8</em> - suggests and changes the current chart’s interval value to the one calculated based on another chart of your choice. The indicator measures the difference in the number of bars between the current chart and the one set in parameters. A typical implementation would be to compare MES tick chart with ES 2000 to find maximum proximity.
        </Column>
        <br />
      </Row>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <aside style={{
        padding: '2rem'
      }}>
        <AnchorLinks mdxType="AnchorLinks">
          <AnchorLink mdxType="AnchorLink">Indicator&nbsp;overview</AnchorLink>
          <AnchorLink mdxType="AnchorLink">Parameters</AnchorLink>
          <AnchorLink mdxType="AnchorLink">Feedback</AnchorLink>
        </AnchorLinks>
      </aside>
      <Column colMd={3} colLg={4} mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem',
          width: "100%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "803px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "64.2361111111111%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACKUlEQVQ4y21TyW7cMAz1hzSRV3mTLMkee/YNmaBABz0V6SnX/v8nvD7Jk2QCzIEgRUmP5CMZ/bMD9tpgezhjOa0xDBM26x1Wqy0WwxLWOnSdQdM0QYyxcK6nrwt3WvdYLVd4e/uLn6czIpHlEEmCOI6RUCc3+/6cpil1Sl/yzSeEwPq4xuVywZlgp9MJkSxkuMyyLOhZ7m3/MUZZ5MxMo8jzAJ5k/MM782IQJzGqssI0TYgKAn5EfCQ5weu2we7UY7Mf8WvZ43oeIZ8SSFvD7A3Ek0BVV+j7fgZ8BJQxA8Gy9VrDDRZVW+H5OYZTDVa9wpUc7n9PKOoCMSuoqgqLxTgDisDXd0DBz8rUsIeOtkBC/jwtMd/9YEaH3uB4dJ+8lmX5laFqJApy5CMJPkjjFGqhMR36wJ0PNnOckbdszp4gQsxBPGBV1xg9h5qj8Od1xHVtUcoC01Jhu3NwGxOacd/te0kD719TUUoJZwyimoC+Q0ujcdxNeDmO6EYNmctQRklugg4y20VeoCDAh+TsfENO++0WkeHQ+iFt2hbe1rqDahVHxMIyorOW/u7T1kohI2jGEoMwoYwZyt0O5fs7olZpKH5Q/JBWJeKCXWPUoB8JwRJ5m4xb6TFFMsuW/sivj2N33DDAOK6Zz4haM0A433z+XPn1YyU+W601NBPx1dWkxY+NZLCo5i4KNaHsRoyLKZRsOotOm7DD9+LvvN/vc+ffBD+D3d56/3+jqYNKk7WEfwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Preview",
              "title": "Preview",
              "src": "/static/9776cf325c7e74b3ece07c071951ea65/644bd/TickChartCompare.png",
              "srcSet": ["/static/9776cf325c7e74b3ece07c071951ea65/7fc1e/TickChartCompare.png 288w", "/static/9776cf325c7e74b3ece07c071951ea65/a5df1/TickChartCompare.png 576w", "/static/9776cf325c7e74b3ece07c071951ea65/644bd/TickChartCompare.png 803w"],
              "sizes": "(max-width: 803px) 100vw, 803px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
      </Column>
      <aside style={{
        padding: '2rem'
      }}>
        <Checkout id="3" lnk="https://buy.stripe.com/5kAeVM5Gl4mabmg007" mdxType="Checkout">$49 - BUY NOW</Checkout>
        <br /><br />
        <p>{`By downloading, buying from this website you `}</p>
        <p>{`acknowledge that  you understand `}</p>
        <p>{`and accept the following agreements:`}</p>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`there is a `}<bold>{`No Refunds Policy`}</bold>{`;`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://www.wizdough.com/terms/"
              }}>{`Terms and Conditions`}</a>{`;`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://www.wizdough.com/risk/"
              }}>{`Full Risk Disclosure`}</a>{`;`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><a parentName="p" {...{
                "href": "https://www.wizdough.com/software/"
              }}>{`Software Agreement`}</a>{`.`}</p>
          </li>
        </ul>
        <br /><br />
        <p><a parentName="p" {...{
            "href": "https://drive.google.com/uc?id=14OrfTwW1PG97wBriKXUzKe6IL1njz_Rx&export=download"
          }}>{`Download`}</a>{` - First time installed will work as a trial for 7 days.`}</p>
      </aside>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={6} colLg={10} mdxType="Column">
        <Share mdxType="Share" />
        <h3>{`Indicator Overview`}</h3>
        <p>{`If you trade MES you probably wanted your chart to be as close as possible to the ES 2000 ticks chart. Determine the exact value of ticks for MES is a tricky part. MES is not an exact copy of ES and MES 2000 ticks chart will look very different from ES 2000 ticks chart. Some traders choose to trade MES at 955 ticks, others pick 995. Why they choose those numbers and how you can check if it’s the correct number? `}</p>
        <p>{`Let’s measure how many bars did RS 2000 generated for the last 60 minutes and let’s find out how many bars we got at MES 955. If those two numbers are about the same then 955 fits well. But sometimes, more often than you think, MES 955 is too far from being a match to ES 2000. `}</p>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem',
          width: "100%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1080px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "37.84722222222222%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABV0lEQVQoz2WQW3KDMAxFWUg64LcxBuyQEvKatCUf3f+GbiUnmUnbjzOW5asrS5VUEkopSCn/w3khC40QaBpR8qF1EJLrJLTS8L2HCw7L/oCKxTWJxcPkac6nIAPtDY6XjGvukVKH93nE7bJF1gabuoHxFumcYK3FMAyobEfOOcJqDW01ajbRdzNlNIbDgDY6pNYjdB7WWThrsA4R71S3nDLc4KGkQt/3qPojPfQByy7he8mY+habtxouehzXiTobNLUoI4uCLKc2BvNuwud5T78099G9R8UjCLrwl3No8TWN2FOndE1oaVcsNFT8F+c8jT8j5W2pdc5hXW+o+NEylJSUtMbitGzRjRGKcpa6mhds0Zii4z3HrkMaR8QYaccZlSIBIx8nU9PCJRe85H5BxgWKDTegOISAzIYcBOrCZ8dx2955jZ/3J6wnPK3odP3AfDiWnWYa/wdL5uwIhGH+bwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "One hour period at ES 2000 has 80 bars.",
              "title": "One hour period at ES 2000 has 80 bars.",
              "src": "/static/68f02a37489408cf8b697bcaae42dc36/4d206/ES.png",
              "srcSet": ["/static/68f02a37489408cf8b697bcaae42dc36/7fc1e/ES.png 288w", "/static/68f02a37489408cf8b697bcaae42dc36/a5df1/ES.png 576w", "/static/68f02a37489408cf8b697bcaae42dc36/4d206/ES.png 1080w"],
              "sizes": "(max-width: 1080px) 100vw, 1080px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
          <br />
One hour period at ES 2000 has 80 bars.
        </div>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem',
          width: "100%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.79166666666667%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABXElEQVQoz42R2U7DMBBF8yXUYzuO7TSOE5I2lLII8YD4//+5XKeLigSIhyONZ5/rSmuN/yCi4WoLY/gmKUU4YyGMGWPWnL7vUa0FTFZKfm7GYrURtFPEx3HEcZ9weMjYzx2O0a91GyIiGIYB1YaGrQ3SNsKsm8g69boZk62r0e23sNykaz2CdzDW4IVbHroWqY/wfYPdbo/qoWOj3GAZtnibEx6XjJanqHPD2lqkpYNxdm2uRK5DDS/b5Q7vTzv0Q0CMLarnGLEcE8RqTJyYUsDn3KPRBndaMB0yMpPLWeZm82IrUfCsn6aZ+tbIOaPyNJaxheI5qmhJIn2vnDzMW4QcIJuT8L+hzx8zjiMqc25U9LE8r1B+9L4LeOJ2JW7/oMRd7dA0zWlD4ygwH/qG8hb6hYn6Enc3OcVX4CWaOPpiCJQroQrUwPPxDe9PXOyf/KWubVcaUrPpON7jC5BXBiogsYNwAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "The same one hour period at MES 955 has 100 bars.",
              "title": "The same one hour period at MES 955 has 100 bars.",
              "src": "/static/f69aa9e57a944b981e6d22933cca5595/3cbba/MES.png",
              "srcSet": ["/static/f69aa9e57a944b981e6d22933cca5595/7fc1e/MES.png 288w", "/static/f69aa9e57a944b981e6d22933cca5595/a5df1/MES.png 576w", "/static/f69aa9e57a944b981e6d22933cca5595/3cbba/MES.png 1152w", "/static/f69aa9e57a944b981e6d22933cca5595/ecbf4/MES.png 1272w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
          <br />
The same one hour period at MES 955 has 100 bars.
        </div>
        <p>{`Wizdough Chart Sync Indicator lets you visualize this problem to make adjustments.`}</p>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem',
          width: "70%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "803px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "64.2361111111111%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACKUlEQVQ4y21TyW7cMAz1hzSRV3mTLMkee/YNmaBABz0V6SnX/v8nvD7Jk2QCzIEgRUmP5CMZ/bMD9tpgezhjOa0xDBM26x1Wqy0WwxLWOnSdQdM0QYyxcK6nrwt3WvdYLVd4e/uLn6czIpHlEEmCOI6RUCc3+/6cpil1Sl/yzSeEwPq4xuVywZlgp9MJkSxkuMyyLOhZ7m3/MUZZ5MxMo8jzAJ5k/MM782IQJzGqssI0TYgKAn5EfCQ5weu2we7UY7Mf8WvZ43oeIZ8SSFvD7A3Ek0BVV+j7fgZ8BJQxA8Gy9VrDDRZVW+H5OYZTDVa9wpUc7n9PKOoCMSuoqgqLxTgDisDXd0DBz8rUsIeOtkBC/jwtMd/9YEaH3uB4dJ+8lmX5laFqJApy5CMJPkjjFGqhMR36wJ0PNnOckbdszp4gQsxBPGBV1xg9h5qj8Od1xHVtUcoC01Jhu3NwGxOacd/te0kD719TUUoJZwyimoC+Q0ujcdxNeDmO6EYNmctQRklugg4y20VeoCDAh+TsfENO++0WkeHQ+iFt2hbe1rqDahVHxMIyorOW/u7T1kohI2jGEoMwoYwZyt0O5fs7olZpKH5Q/JBWJeKCXWPUoB8JwRJ5m4xb6TFFMsuW/sivj2N33DDAOK6Zz4haM0A433z+XPn1YyU+W601NBPx1dWkxY+NZLCo5i4KNaHsRoyLKZRsOotOm7DD9+LvvN/vc+ffBD+D3d56/3+jqYNKk7WEfwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Here the indicator calculates a target tick period for MES chart based on a comparison of how many bars do ES and MES tick charts contain for a period of time.",
              "title": "Here the indicator calculates a target tick period for MES chart based on a comparison of how many bars do ES and MES tick charts contain for a period of time.",
              "src": "/static/9776cf325c7e74b3ece07c071951ea65/644bd/TickChartCompare.png",
              "srcSet": ["/static/9776cf325c7e74b3ece07c071951ea65/7fc1e/TickChartCompare.png 288w", "/static/9776cf325c7e74b3ece07c071951ea65/a5df1/TickChartCompare.png 576w", "/static/9776cf325c7e74b3ece07c071951ea65/644bd/TickChartCompare.png 803w"],
              "sizes": "(max-width: 803px) 100vw, 803px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
          <br />
Here the indicator calculates a target tick period for MES chart based on a comparison of how many bars do ES and MES tick charts contain for a period of time. 
        </div>
        <p>{`Click the Sync button and the current chart period will change to the one calculated by the indicator.`}</p>
        <h3>{`Parameters`}</h3>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem',
          width: "50%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "498px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "162.8472222222222%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAhCAYAAADZPosTAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADwUlEQVRIx51W23baQAzkV9IQaC6ADfh+v2ECJGlPe3r6/z+iarSWYzt+6oPOJmt7VtLMaFns9wfabDZ0d/eN7u+X/x3L5ZK2W4sWnueTc3To5WVDq9WaHh7Wsq7X3/vA/6vVqlvnA+/tdjYtyrKmy+VGbftKnuuT5wXk8mrbBwnLOvBeSEEQk+vg2VyE5DjmmwU+LsuKsiwngBdFxR9Hcppl7aWMNC2pKs+UxCWlScVRTqKiMEgNoO+HVFUNA5UCFkWJgCkg1sCP+cCaAQsBHUchgEmcK2DAgDXleclRCOjh4IwyzLKK3m4/KY4YMJkBTLlCrsK2ugzr+iSAyDIMYyZoK0AaaVLQuX0zAF8ATdmjktE7gGJN01yIwX4QhJJpHGVU5A1/lFE8U/YIEAQACACO4wkhCoZsAQhSPt5/UVO/8rttT4RGltay15dcct/2+6MAIhQceyg5DBKu4MxMn7jkgnwvEqL8LgJ+HoaJSGyBEqHBy/UmbIOkJMmEbZSODFHKZrOTwAGqgmFgX0oGGe35wuK+Sv+QGVh+ft7IS2Ba2f4EsGbCNiXDenlWsDQKybBpWlnRy2HJYBpO8dgVGm4X3tApyBAM1x0I+ocAIQoIe0VRyp5n3zMoVgHnXgo4r9gXQJSJrOq6kdI2A/1pib4fsU7BaM5tKeTvJM4Y1O/9jEMEEM5AycejKxmpQzR6QHZLzCAJg+JvrCa7rmQFRIYYDgCcgikg+hOFqZQNANs+0mHv0vHg8XeeZIhDRTY6bcDudlJu72UW9o+P3/TBcbl8UHu60qm5stxurOG2Gw7Fp1PAsCuz0BdCoL+hXDAc4BSANPWFfc3z83Sj1/O7OKd3CgABZmagYVYDD3tSmEWMJyObqJeLkYxxDQawlJzlZg6CEHXCkGFkCXBMIHXL1zDP+pIhZOgRxAzdoaSAEEwT6M2fC84Q2QogymuakwwITB317xAQ5eDD2Tul2wPbPSCAdHTpDBwDJtKnXnfD4D2dPh1gJKMfLAMUvTBA+9GdIpZzjOWcURi3eBwyHFQ28DMsOBW49JAFXRaNZDLto9f1EMI2V4BnLikAR1E8CwjLVeUn4ChQ8lA2sB4yVAkMr8+hbJ6eXkQ6Kp+hqzR6YSdxOpLLNEP0NuaDdbS5/P/U83Kw1QHGE8BhbOEU/u1T+ObnCFqDA6aHaiULvTrnAHd4ict75jvm4f0HTxhzkUERwx8DI0C8gEtpWqoCWugtu+jxz1+WTyiXFyoaZjkC1Btr+lBWEANQJsJeP86Otyl5/wChSze3yv6PMwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Settings",
              "title": "Settings",
              "src": "/static/b82a2d6bd8f9d49161861b5edfda4a7a/a0e61/settings.png",
              "srcSet": ["/static/b82a2d6bd8f9d49161861b5edfda4a7a/7fc1e/settings.png 288w", "/static/b82a2d6bd8f9d49161861b5edfda4a7a/a0e61/settings.png 498w"],
              "sizes": "(max-width: 498px) 100vw, 498px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
          <br />
Settings.
        </div>
        <p>{`You can set what instrument you want to compare your current chart.
By default, expiry is empty, which means that your current chart’s expiry will be used. It’s possible to compare to charts not only tick-based but also any other types. So, you could compare your MES 1200 ticks with MES 5 min.`}</p>
        <p>{`The smoothing period helps to avoid unnecessary fluctuations if you want to keep your tick period more or less stable.`}</p>
        <p>{`Timespan to compare in minutes is a period of time back we use to count bars.`}</p>
        <p>{`Output can be one of these 3 options:`}</p>
        <ul>
          <li parentName="ul">{`Difference_Bars - after counting the number of bars of both charts we get this difference.`}</li>
          <li parentName="ul">{`Delta_Ticks - Difference_Bars in ticks. Basically, tells how much we should change the current tick period.`}</li>
          <li parentName="ul">{`Target_Ticks - Target_Ticks plus the current tick period. This value should be set as the target tick period.`}</li>
        </ul>
        <p>{`As described above we can click the refresh button to sync on the calculated tick period. Auto Send Enter - will also press “Enter” to submit the value.`}</p>
        <Share mdxType="Share" />
        <h3>{`Feedback`}</h3>
        <hr></hr>
      </Column>
    </Row>
    <br /><br />
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={6} mdxType="Column">
        <p>{`Have you got questions or feedback?`}</p>
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and our team will contact you back. `}</p>
      </Column>
      <Column colMd={3} colLg={4} mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      